import * as client_hooks from '../../../src/hooks.client.ts';

export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19')
];

export const server_loads = [];

export const dictionary = {
		"/": [3],
		"/about": [4],
		"/app/about": [5,[],[2]],
		"/app/explore": [6,[],[2]],
		"/app/explore/user/[slug]": [8,[],[2]],
		"/app/explore/[slug]": [7,[],[2]],
		"/app/home": [9,[],[2]],
		"/app/home/[slug]": [10,[],[2]],
		"/app/noti": [11,[],[2]],
		"/app/user": [12,[],[2]],
		"/badrequest": [13],
		"/banned": [14],
		"/error": [15],
		"/notfound": [16],
		"/privacy": [17],
		"/showcase": [18],
		"/tos": [19]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),
};

export { default as root } from '../root.svelte';